import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

const Error500: React.FC = () => {
  return (
    <Container className="text-center my-5">
      <Row>
        <Col>
          <h1 className="display-1 text-danger fw-bold">500</h1>
          <h2>Internal Server Error</h2>
          <p>Có lỗi phát sinh. Vui lòng liên hệ với chúng tôi</p>
          <Button variant="secondary" href="/">
            Quay lại trang chủ
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Error500;