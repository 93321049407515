import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import GetCatalogList from '../APIs/catalogService';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { ChevronLeft, Search } from 'react-bootstrap-icons';
import { IHtDanhMucChiTiet } from '../Dtos/Entities/IHtDanhMucChiTiet';
import { Button, Card, Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import { removeAccents } from '../Utils/dataFilter';
import { SqlDataTypes } from '../Utils/SqlDataTypes';
import { NumericFormat } from 'react-number-format';
import { FormCheck } from 'react-bootstrap';

const Catalog: React.FC = () => {
  const { catName, cat } = useParams<{ catName: string, cat: string }>();

  const [items, setItems] = useState(new Array<any>());
  const [settings, setSetting] = useState(new Array<IHtDanhMucChiTiet>());
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const itemPerPage = 20;

  useEffect(() => {
    LoadData();
  }, [])

  const LoadData = async () => {
    setIsLoading(true);
    const rs = await GetCatalogList({ tableName: catName, textSearch: searchTerm });
    setIsLoading(false);
    setItems(rs.Data.Data);
    setSetting(rs.Data.Settings);
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = removeAccents(event.target.value);
    setSearchTerm(term);
  };



  return (
    <div className='p-2'>
      <div className='loadding' style={{ display: isLoading ? "flex" : "none" }}>
        <Spinner animation="border" variant="primary" />
      </div>

      <Navbar className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="/cats" className={`text-center font-size-20`}>
            <ChevronLeft fontSize={30}></ChevronLeft>
            {cat}
          </Navbar.Brand>
        </Container>
      </Navbar>

      <div className='home-logo' style={{display: 'flex'}}>
      <Form.Control
            onChange={handleSearch}
            placeholder='Nhập text để tìm kiếm'
          />
        <Button variant='primary' style={{width : 70}} onClick={()=>{LoadData()}}>
          <Search></Search><span style={{fontSize : 12}}>Tìm</span>
          </Button>
      </div>

      {
        items?.map((i: any) => {
          return <Card className='mt-1'>
            <Card.Body style={{ padding: 5, fontSize: 13 }}>
              {
                settings?.map((s: IHtDanhMucChiTiet) => {
                  return <>
                    <Row>
                      <Col className={(s.IsFontBold ? "text-left text-secondary text-dark-red" : "text-left text-secondary")} md="4" xs="4">{s.TenHienThi}: </Col>
                      {(() => {
                        var value = i[s.TuBang === null || s.TuBang === '' ? s.Truong : `${s.TuBang}_${s.Truong}_${s.TruongHienThi}`];
                        if (s.SqlDataType === SqlDataTypes.Decimal || s.SqlDataType === SqlDataTypes.Float || s.SqlDataType === SqlDataTypes.Money) {
                          return <Col className='text-left' md="8" xs="8">
                            <NumericFormat
                              className={(s.IsFontBold ? "text-dark-red" : "")}
                              thousandSeparator="," decimalSeparator="." decimalScale={2}
                              value={value === null ? 0 : value} disabled={true} displayType='text'></NumericFormat>
                          </Col>
                        }
                        if (s.SqlDataType === SqlDataTypes.Bit) {
                          return <Col className='text-left' md="8" xs="8">
                            <FormCheck disabled={true} value={value == null ? false : value} className={(s.IsFontBold ? "text-dark-red" : "")} />
                          </Col>
                        }
                        else {
                          return <Col className={(s.IsFontBold ? "text-left text-dark-red" : "text-left")} md="8" xs="8">{value}</Col>
                        }
                      })()}

                    </Row>
                  </>
                })
              }
            </Card.Body>
          </Card>
        })
      }
    </div>

  );
}

export default Catalog;