import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { HouseExclamationFill, PersonCircle, CardList, MenuButtonWideFill } from 'react-bootstrap-icons';

interface MenuProps {
  active: number;
}

const Menu: React.FC<MenuProps> = ({active}) => {


  return (
    <>
      <Navbar className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="/" className={`fs-6 text-center font-size-menu ${active === 1? "text-primary" : "text-dark"}`}>
          <HouseExclamationFill fontSize={35}></HouseExclamationFill>
            <br/>
            Trang Chủ
          </Navbar.Brand>

          <Navbar.Brand href="/cats"  className={`fs-6 text-center font-size-menu ${active === 2? "text-primary" : "text-dark"}`}>
          <MenuButtonWideFill fontSize={35}></MenuButtonWideFill>
            <br/>
            Danh mục
          </Navbar.Brand>

          <Navbar.Brand href="/order"  className={`fs-6 text-center font-size-menu ${active === 3? "text-primary" : "text-dark"}`}>
          <CardList fontSize={35}></CardList>
            <br/>
            Hóa đơn
          </Navbar.Brand>

          <Navbar.Brand href="/user"  className={`fs-6 text-center font-size-menu ${active === 4? "text-primary" : "text-dark"}`}>
          <PersonCircle fontSize={35}></PersonCircle>
            <br/>
            Tài khoản
          </Navbar.Brand>
        </Container>
      </Navbar>
    </>

  );
}

export default Menu;