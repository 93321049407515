import React, { useEffect, useState } from 'react';
import Menu from '../Components/Menu';
import GetMenus from '../APIs/menuService';
import { Col, Form, InputGroup, ListGroup, Row } from 'react-bootstrap';
import { ITblMpfeature } from '../Dtos/Entities/ITblMpfeature';
import { removeAccents } from '../Utils/dataFilter';
import { Search } from 'react-bootstrap-icons';


const Catalogs: React.FC = () => {

  const [cats, setCats] = useState(new Array<ITblMpfeature>());
  const [allCats, setAllCats] = useState(new Array<ITblMpfeature>());
  const [searchTerm, setSearchTerm] = useState<string>('');
  useEffect(() => {
    LoadData();
  },[])


  const LoadData = async () => {
    const rs = await GetMenus();
    setCats(rs.Data);
    setAllCats(rs.Data);
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = removeAccents(event.target.value);
    setSearchTerm(term);

  };

  useEffect(() => {
    if (searchTerm === '') {
      setCats(allCats);
    } else {
      const matchedItems = allCats.filter((e: any) =>
        removeAccents(e.Ten).includes(searchTerm)
      )
      if (matchedItems.length > 0) {
        setCats(matchedItems);
      } else {
        setCats([]);
      }
    }
  }, [searchTerm])

  return (
    <>
      <Menu active={2} />
      <Row className='home-logo'>
        <Col>
          <InputGroup className="mb-1 mt-3">
          <InputGroup.Text id="basic-addon1"><Search></Search></InputGroup.Text>
            <Form.Control
              placeholder="Tìm kiếm"
              onChange={handleSearch}
            />
          </InputGroup>
        </Col>

      </Row>
      <ListGroup>
        {
          cats.map((c,index)=>{
            return <ListGroup.Item className='text-left font-size-14 p-3'>
              <a href={`/cat/${c.Ma}/${c.Ten}`} className='text-black text-decoration-none'>
              <span className="fw-bold text-danger me-2">
              {index}
              </span>
              {c.Ten}
              </a>
              </ListGroup.Item>
          })
           
        }
      </ListGroup>
    </>

  );
}

export default Catalogs;