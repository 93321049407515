import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Layouts/Home';
import Login from './Layouts/Login';
import Catalogs from './Layouts/Catalogs';
import Catalog from './Layouts/Catalog';
import Error500 from './Layouts/Error500';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/cats" element={<Catalogs />} />
          <Route path="/cat/:catName/:cat" element={<Catalog />} />
          <Route path="/error500" element={<Error500 />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
