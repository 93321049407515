import React from 'react';
import Menu from '../Components/Menu';

const Home: React.FC = () => {

  return (
    <>
      <Menu  active={1}/>
    </>

  );
}

export default Home;