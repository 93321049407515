import React, { useState } from 'react';
import UserLogin from '../APIs/loginService';
import { setCookie } from '../Utils/cookieUtils';
import { Button, Col, Modal, Row, Toast, ToastContainer } from 'react-bootstrap';

const Login: React.FC = () => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");


  const [showForgot, setShowForgot] = useState(false);
  const [showRes, setShowRes] = useState(false);
  const [showToast, setShowToast] = useState(false);


  const LoginAction = async (event: React.FormEvent) => {
    event.preventDefault();
    const rs = await UserLogin({
      userName: username,
      password: password
    });

    if (rs.Success) {
      setCookie('token', rs.Data.Token, 10);
      window.location.href = "/";
    } else {
      setShowToast(true);
    }

  }

  return (
    <div className='main-login position-relative'>
      <div className='container'>
        <div style={{ width: 370, height: 400 }}>
          <h1 className='mb-5 text-primary fw-bold font-size-logo'>PAMOSA</h1>

          <div className="mb-3 mt-2">
            <input type="text" className="form-control rounded-5" id="userName" aria-describedby="" placeholder='Số Điện Thoại'
              onChange={(e) => { setUserName(e.target.value) }} />
          </div>
          <div className="mb-3">
            <input type="password" className="form-control rounded-5" id="password"
              onChange={(e) => { setPassword(e.target.value) }} placeholder='Mật khẩu' />
          </div>
          <button type="submit" className="btn btn-primary rounded-5" onClick={LoginAction}>Đăng nhập</button>


        </div>

      </div>
      <p className='position-absolute m-bottom-50 text-white font-size-14' onClick={() => { setShowRes(true) }}>Đăng ký tài khoản mới</p>
      <p className='position-absolute m-bottom-30 text-white font-size-14' onClick={() => { setShowForgot(true) }}>Quên mật khẩu</p>

      <Modal show={showForgot} onHide={() => { setShowForgot(false) }}>

        <Modal.Body className='text-center'>
          <Modal.Title className='text-center'>Quên mật khẩu?</Modal.Title>
          <p className='font-size-14 mt-2 '>
            Vui lòng liên hệ zalo sau để được trợ giúp
          </p>
          <Row>
            <Col>
            <img src='/zalo.jpg' alt='' width={60}></img>
            </Col>
            <Col>
            <span className='mt-1 fw-bold text-primary' style={{fontSize:40, marginLeft:-40}}>0356.308.661</span>
            </Col>
          </Row>
          <Button variant='secondary ' onClick={() => { setShowForgot(false) }}>
            Trở về
          </Button>
        </Modal.Body>
      </Modal>

      <Modal show={showRes} onHide={() => { setShowRes(false) }}>

        <Modal.Body className='text-center'>
          <Modal.Title className='text-center'>Đăng ký tài khoản mới?</Modal.Title>
          <p className='font-size-14 mt-2 '>
            Vui lòng liên hệ zalo sau để được trợ giúp
          </p>
          <Row>
            <Col>
            <img src='/zalo.jpg' alt='' width={60}></img>
            </Col>
            <Col>
            <span className='mt-1 fw-bold text-primary' style={{fontSize:40, marginLeft:-40}}>0356.308.661</span>
            </Col>
          </Row>
          <Button variant='secondary ' onClick={() => { setShowRes(false) }}>
            Trở về
          </Button>
        </Modal.Body>
      </Modal>

      <ToastContainer position="top-end" className="p-3">
        <Toast show={showToast} onClose={()=>{setShowToast(false)}} delay={5000} autohide bg='Danger'>
          <Toast.Body className='bg-danger text-white'>
            Số điện thoại hoặc nhập khẩu không đúng. Vui lòng xác nhận lại
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </div>


  );
}

export default Login;